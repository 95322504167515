<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader">
      <a-page-header title="微信群管理"> </a-page-header>
    </div>
    <div ref="form" class="form">
      <a-form :form="form" layout="inline" ref="form">
        <!-- <a-form-item>
          <a-input
            v-decorator="['orderId', { rules: [{ required: false }] }]"
            placeholder="订单号"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" @click="getSearch">
            搜索
          </a-button>
        </a-form-item> -->
      </a-form>
      <a-button class="buttonImg" @click="handleCell('新增', num)">
        <img src="../assets/add.png" alt="" />
        新增
      </a-button>
    </div>
    <a-table
      :columns="columns"
      size="middle"
      :scroll="{ x: 700, y: table_h }"
      :pagination="false"
      :data-source="list"
      :row-key="(record) => record.id"
    >
      <div slot="baby_info" slot-scope="baby_info">
        <span v-if="baby_info">
          姓名：{{ baby_info.baby_name }}
          <a-icon
            type="woman"
            v-if="baby_info.baby_sex == 2"
            style="color: rgba(253, 105, 150)"
          /><a-icon
            type="man"
            v-if="baby_info.baby_sex == 1"
            style="color: rgba(253, 105, 150)"
          />
          <br />
          生日：{{ baby_info.baby_birthday }}<br />
          <!-- 性别：{{baby_info.baby_sex==1?"男":"女"}} -->
        </span>
        <span v-else> 预产期：{{ baby_info.expected_date_childbirth }} </span>
      </div>
      <span slot="btns" slot-scope="btns, num">
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('编辑', num)"
        >
          编辑
        </a-button>
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('删除', num)"
        >
          删除
        </a-button>
      </span>
    </a-table>
    <div ref="pagination" style="overflow: hidden">
      <a-pagination
        v-if="pageshow"
        :default-current="currentPage"
        :defaultPageSize="defaultPageSize"
        :page-size-options="pageSizeOptions"
        show-size-changer
        :total="total_num"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
        :item-render="itemRender"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <Edit v-if="$store.state.wxGroupEditShow" />
  </div>
</template>
<script>
import Edit from "@/components/wxGroup/edit.vue";
export default {
  name: "User",
  components: {
    Edit,
  },
  data() {
    return {
      columns: [
        {
          title: "群名称",
          key: "name",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "群ID",
          key: "wx_group",
          dataIndex: "wx_group",
          align: "center",
        },
        {
          title: "群使用次数",
          key: "use_num",
          dataIndex: "use_num",
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      table_h: "",
      list: [],
      pageshow: true,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: this.$store.state.defaultPageSize,
      pageSizeOptions: this.$store.state.pageSizeOptions,
    };
  },
  // 监听是否刷新列表
  computed: {
    canfreshwxGroup() {
      return this.$store.state.canfreshwxGroup;
    },
  },
  watch: {
    canfreshwxGroup: {
      handler(val) {
        if (val) {
          this.getSearch();
          this.$store.commit("changeCanfreshwxGroup", false);
        }
      },
      immediate: true,
    },
    list: {
      handler(val, val1) {
        if (val != val1) {
          this.changeTableH();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        // this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      this.$refs.box.style.display = "flex";
      if (this.table_h <= 250) {
        this.table_h = 400;
        this.$refs.box.style.display = "block";
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.getList(that.search);
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      this.defaultPageSize = pageSize;
      this.$store.commit("changedefaultPageSize", pageSize);
      window.localStorage.setItem("defaultPageSize", pageSize);
      this.getSearch();
    },
    //获取列表
    getSearch() {
      var that = this;
      that.currentPage = 1;
      that.getList({});
    },
    getList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/api/admin/weixinGroup/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var list = res.data.resultObject.data;
            that.list = list;
            that.total_num = res.data.resultObject.hasMore
              ? res.data.resultObject.current_page * data.limit + 1
              : res.data.resultObject.current_page * data.limit;
            that.total_num = that.total_num || 0;
            that.currentPage = res.data.resultObject.current_page;
          }
        });
      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    handleCell(operation, num) {
      if (operation == "删除") {
        var that = this;
        this.$confirm({
          title: "提示",
          content: "是否确认删除此微信群？",
          onOk() {
            var data = { id: num.id };
            that.axios
              .post("/api/admin/weixinGroup/del", data)
              .then((res) => {
                if (that.$code(res)) {
                  that.$message.success("删除成功");
                  that.getSearch();
                }
              });
          },
          onCancel() {},
        });
      } else {
        if (operation == "新增") {
          this.$store.commit("changeWxGroupEditShow", true);
          this.$store.commit("changeWxGroupId", "");
        }
        if (operation == "编辑") {
          this.$store.commit("changeWxGroupEditShow", true);
          this.$store.commit("changeWxGroupId", num.id);
        }
      }
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253, 105, 150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}
</style>